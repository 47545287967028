<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    v-if="!!entity"
    :showDeleteButton="true"
  />
</template>
<script>
import { mapGetters } from "vuex";

import FormPage from "@/components/penal-retail-helpers/FormPage";
let collectionName = "zones";

import zonesFormConfig from "@/helpers/zonesFormConfig";
import { db } from "../../../firebase/firebaseConfig";

import { createRelatedCasesColumns } from "@/helpers/utils";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allEstablishments", "allCases"]),
  },
  data() {
    let attributes = [].concat(
      zonesFormConfig(this, this.$store.state.AppActiveUser)
    );

    let data = {
      entity: null,
      collectionName,
      tabs: [
        { name: `${collectionName}.tabs.general`, attributes: attributes },
        {
          name: `${collectionName}.tabs.cases`,
          attributes: this.defineCasesTab(),
        },
      ],
    };

    return data;
  },
  created() {
    this.$bind(
      "entity",
      db.collection(this.collectionName).doc(this.$route.params.id),
      {
        wait: true,
      }
    );
  },
  methods: {
    defineCasesTab() {
      let columns = createRelatedCasesColumns(this);

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.allCases.filter((x) =>
              this.entity.establishments.includes(x.establishment.id)
            );
          },
          filters: [],
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: (params) => {
              this.$router
                .push("/main/cases/" + params.data.caseId)
                .catch(() => {});
            },
          },
        },
      });

      return config;
    },
    prevalidate() {
      return false;
    },
  },
};
</script>
