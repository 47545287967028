export default (self, user, isForCreate) => {
  let fields = [];
  fields.push({
    size: "1/2",
    type: "input",
    attribute: "alias",
    validation: "required",
  });
  if (["admin", "manager"].includes(user.role)) {
    fields.push({
      size: "1/2",
      type: "select",
      attribute: "customer",
      validation: "required",
      readonly: !isForCreate,
      extra: { optionsFunction: () => self.allCustomers },
    });

    fields.push({
      size: "1/2",
      type: "multiple-select",
      attribute: "establishments",
      validation: "required",
      valueFormatter(value) {
        return value ? value.id : value;
      },
      extra: {
        optionsFunction: (model) => {
          return self.allEstablishments
            .filter((x) => x.customer.id === (model.customer || {}).id)
            .map((x) => {
              return x.id === "empresa"
                ? {
                    ...x,
                    id: x.id,
                    alias: self.$t("establishments.labels.wholeCompany"),
                  }
                : x;
            });
        },
      },
      visibility(model) {
        return !!model.customer;
      },
    });
  } else {
    fields.push({
      size: "1/2",
      type: "multiple-select",
      attribute: "establishments",
      validation: "required",
      valueFormatter(value) {
        return value ? value.id : value;
      },
      extra: {
        optionsFunction: () => {
          return self.allEstablishments.map((x) => {
            return x.id === "empresa"
              ? {
                  ...x,
                  id: x.id,
                  alias: self.$t("establishments.labels.wholeCompany"),
                }
              : x;
          });
        },
      },
    });
  }
  fields.push({ size: "1/1", type: "text", attribute: "description" });

  return fields;
};
